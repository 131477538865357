import * as React from 'react';

class About extends React.Component {
  componentDidMount() {
    window.location.replace('https://z4c9d1rojbd.typeform.com/to/nUgSiyWQ');
  }
  render() {
    return null;
  }
}

const ContactPage = ({ data }) => {
  return (
    <>
      <About />
    </>
  );
};

export default ContactPage;
